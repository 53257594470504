import Chef from "./chefAbi";
import {SwapPlatforms} from "@/config/swapPlatforms";

export const config = {
    name: "CafeSwap",
    url: "https://polygon.cafeswap.finance/pools",
    chef: {
        address: "0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5",
        rewardTokenTicker: "pBREW",
        abi: Chef,
        rewardTokenFunction: "brew",
        pendingRewardsFunction: "pendingBrew"
    },
    swap: SwapPlatforms.OneInch
}

export default config;
